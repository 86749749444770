import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'

const About = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    // console.log(data)

    useMount(() => {
        theme.setTheme({
            headerColour: colours.blue,
            bgColour: colours.peach,
            footerColour: colours.blue
        })
    })

    const renderAboutUs = () => {
        return (
            <AboutUs>
                <Heading>About Us</Heading>
                {data.about_content && (
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data.about_content,
                        }}
                    />
                )}
            </AboutUs>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.blue}
        >
            <Wrapper>
                <Container>
                    <Intro
                        dangerouslySetInnerHTML={{
                            __html: data.about_intro,
                        }}
                    />
                    <Grid>
                        {renderAboutUs()}
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const ExternalLink = styled.a``
const Items = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex justify-between`};
    margin-bottom: 12rem;

    ${media.mob`
        ${tw`block`};
    `}
`


// Introduction

const Intro = styled.div`
    width: 45rem;
    max-width: 100%;
    padding-top: 9rem;
    padding-bottom: 8rem;

    &, * {
        ${tw`font-display text-blue`};
        font-weight: 300;
        font-size: 2.25rem;
        line-height: 1.4;
    }

    > *:not(:first-child) {
        margin-top: 1em;
    }
`


// About Us

const AboutUs = styled.div`
    width: 47.5rem;
    max-width: 100%;

    ${media.mob`
        ${tw`w-full`};
    `}

    ${Heading} {
        ${type.heading}
    }

    ${Description} {
        margin-top: 2.5rem;

        &, * {
            ${type.body}
        }

        p:not(:first-child) {
            margin-top: 1em;
        }
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
