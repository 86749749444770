import React, { useState, useContext, useMemo, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'
import { motion } from 'framer-motion'

const categories = [
    {
        value: 'hotel',
        label: 'Hotel'
    },
    {
        value: 'cruise',
        label: 'Cruise'
    },
    {
        value: 'destination',
        label: 'Destination'
    },
    {
        value: 'airline',
        label: 'Airline'
    },
    {
        value: 'all',
        label: 'All'
    },
]

const Clients = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    const [selectedCategory, setSelectedCategory] = useState('all')
    const [animate, setAnimate] = useState(false)
    const selectedCategoryRef = useRef(selectedCategory)
    
    // // track previous selectedCategory
    // useEffect(() => {
    //     selectedCategoryRef.current = selectedCategory
    // }, [selectedCategory])

    // const previousSelectedCategory = selectedCategoryRef.current

    // // set animate to true when selectedCategory changes
    // useEffect(() => {
    //     if (selectedCategory === previousSelectedCategory) return
    //     if (!animate) {
    //         setAnimate(true)
    //     }
    // }, [selectedCategory, previousSelectedCategory, animate])

    // // set animate to false after 0.5 seconds
    // useEffect(() => {
    //     if (!animate) return
    //     const timeout = setTimeout(() => {
    //         setAnimate(false)
    //     }, 500)

    //     return () => clearTimeout(timeout)
    // }, [animate])
    
    // filter items based on selected category
    const filteredItems = useMemo(() => {
        if (!data.our_clients) return []

        return data.our_clients.filter(item => {
            return selectedCategory === 'all' || item.client_category === selectedCategory
        })
    }, [selectedCategory])

    useMount(() => {
        theme.setTheme({
            headerColour: colours.blue,
            bgColour: colours.white,
            footerColour: colours.blue
        })
    })

    const renderFilters = () => {
        const items = categories.map((category, i) => {
            return (
                <Filter
                    key={i}
                    onClick={() => setSelectedCategory(category.value)}
                    active={selectedCategory === category.value}
                >
                    <Label>
                        {category.label}
                    </Label>
                </Filter>
            )
        })

        return (
            <Filters>
                {items}
            </Filters>
        )
    }

    const renderItems = () => {
        if (!filteredItems) return <></>

        const items = filteredItems.map((item, i) => {
            const { client_image, client_link, client_name, client_category } = item
            return (
                <Item
                    key={client_name || i}
                    as={ExternalLink}
                    href={`${item?.client_link}`}
                    target="_blank"
                >
                    <ImageWrapper>
                        {client_image && (
                            <Image
                                key={client_image.sizes?.medium2}
                                src={client_image.sizes?.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <LoadedImage
                                            src={src}
                                            style={{opacity: loading ? 0 : 1}}
                                        />
                                    )
                                }}
                            </Image>
                        )}
                    </ImageWrapper>
                    {client_name && (
                        <Subheading>
                            {client_name}
                        </Subheading>
                    )}
                </Item>
            )
        })

        return (
            <motion.div
                initial={{
                    opacity: 1
                }}
                animate={animate ? {
                    opacity: 0,
                    transition: {
                        duration: 0
                    }
                } : {
                    opacity: 1,
                    transition: {
                        duration: 0.4
                    }
                }}
            >
                <Grid>
                    {items && items}
                </Grid>
            </motion.div>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.blue}
        >
            <Wrapper>
                <Container>
                    {renderFilters()}
                    {renderItems()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const ExternalLink = styled.a``
const Items = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.4s ease;
`

const ImageWrapper = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}
`

// Filters

const Filters = styled.div`
    ${tw`flex justify-between -mx-4`};
    margin-top: 9rem;
`

const Label = styled.div``

const Filter = styled.div`
    ${tw`p-4 cursor-pointer`};
    user-select: none;

    ${Label} {
        ${tw`text-black uppercase border-b border-transparent`};
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        transition: all 0.4s ease;
    }
    
    &:hover ${Label} {
        ${tw`text-blue border-blue`};
    }

    ${props => {
        if (props.active) {
            return css`
                ${Label} {
                    ${tw`text-blue border-blue`};
                }
            `
        }
    }}
`

// Items

const Grid = styled.div`
    ${tw`flex flex-wrap -mx-3`};
    margin-top: 7rem;
    margin-bottom: 8rem;

    ${media.mob`
        ${tw`block mx-0`};
        margin-top: 2rem;
    `}

    ${Item} {
        ${tw`w-1/2 text-black px-3 mb-10`};
        transition: color 0.4s ease;

        ${media.mob`
            ${tw`w-full`};
        `}

        ${Subheading} {
            ${tw`font-display mt-4`};
            font-weight: 300;
            font-size: 1.6875rem;
            line-height: 1.56;
        }

        ${ImageWrapper} {
            ${tw`relative w-full h-0`};
            padding-bottom: 87.583149%;

            ${LoadedImage} {
                ${tw`absolute inset-0 w-full h-full`};
                object-fit: cover;
                object-position: center;
            }
        }

        &:hover, &:active {
            ${tw`text-blue`};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "clients" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Clients
