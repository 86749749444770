import React, { useRef, useContext } from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { container, padding, bgImage, bgIcon, colours } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'
import { headerHeight } from '../Header/Header'

import { IsMobileContext } from '../../context/IsMobileContext'

import 'swiper/css/swiper.css'

const ServicesSlider = props => {
    const { slides } = props
    const swiperRef = useRef(null)
    const canLoop = slides && slides.length > 1
    const { isMobile } = useContext(IsMobileContext)

    const params = {
        loop: canLoop,
        speed: 500,
        effect: 'fade',
        autoplay: {
            delay: 2000,
        },
    }

    const renderSlides = () => {
        if (!slides) return

        return slides.map((item, i) => {
            return (
                <Slide key={i}>
                    {item.image && (
                        <BGImage 
                            image={item?.image?.sizes?.medium2}
                        />
                    )}
                </Slide>
            )
        })
    }

    return (
        <Wrapper>
            <Swiper {...params} ref={swiperRef}>
                {renderSlides()}
            </Swiper>
        </Wrapper>
    )
}

// Layout

const SlideNext = styled.div``
const SlidePrev = styled.div``

const Wrapper = styled.div`
    ${tw`relative w-full`};
    height: 418px;

    ${media.mob`
        height: 400px;
    `}

    /* custom swiper styles */
    .swiper-container {
        width: 100%;
        height: 100%;
    }
`

// Slide

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    transition: opacity 0.4s ease;
`

const Slide = styled.div`
    ${tw`relative w-full h-full`};

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
    }
`

export default ServicesSlider
