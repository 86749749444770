import React, { useState, useContext, useMemo, useRef, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'
import { motion } from 'framer-motion'

import { parseACF } from '../utils'
import { Layout, ServicesSlider } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'

const ActiveItem = ({activeItem, item}) => {
    if (!item?.images) return <></>
    const fadeVariants = {
        hidden: {
            opacity: 0,
            display: 'none',
            transition: {
                duration: 0
            }
        },
        visible: {
            opacity: 1,
            display: 'block',
            transition: {
                duration: 0.4
            }
        }
    }

    return (
        <motion.div
            initial={ 'hidden' }
            animate={ (activeItem === item.service) ? 'visible' : 'hidden' }
            variants={fadeVariants}
        >
            <ServicesSlider
                slides={item.images}
            />
        </motion.div>
    )
}

const ServicesListing = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    const [activeItem, setActiveItem] = useState(data?.our_services?.[0]?.service || {})
    const activeItemRef = useRef(activeItem)
    const [animate, setAnimate] = useState(false)
    
    // track previous activeItem
    useEffect(() => {
        activeItemRef.current = activeItem
    }, [activeItem])

    const previousActiveItem = activeItemRef.current

    // // set animate to true when activeItem changes
    // useEffect(() => {
    //     if (activeItem === previousActiveItem) return
    //     if (!animate) {
    //         setAnimate(true)
    //     }
    // }, [activeItem, previousActiveItem, animate])

    // set animate to false after 0.5 seconds
    useEffect(() => {
        if (!animate) return
        const timeout = setTimeout(() => {
            setAnimate(false)
        }, 500)

        return () => clearTimeout(timeout)
    }, [animate])
    
    useMount(() => {
        theme.setTheme({
            headerColour: colours.white,
            bgColour: '#d6c6ad',
            footerColour: colours.white
        })
    })

    const renderServices = () => {
        if (!data.our_services) return <></>

        const items = data.our_services.map((item, i) => {
            return (
                <Item
                    key={i}
                    onClick={() => setActiveItem(item?.service)}
                    active={activeItem === item.service}
                >
                    {item.service}
                </Item>
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    const activeItemData = useMemo(() => {
        return data.our_services.find(item => item.service === activeItem)
    }, [activeItem])

    const renderActiveItem = () => {
        if (!activeItemData) return <></>
        if (!activeItemData.images) return <></>

        return (
            <ServicesSlider
                key={activeItemData.service}
                slides={activeItemData.images}
            />
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.white}
        >
            <Wrapper>
                <Container>
                    <Intro
                        dangerouslySetInnerHTML={{
                            __html: data.services_intro,
                        }}
                    />
                    <Heading>Our Services</Heading>
                    <Grid>
                        <Left>
                            {renderServices()}
                        </Left>
                        <Right>
                            {/* <motion.div
                                initial={{
                                    opacity: 1
                                }}
                                animate={animate ? {
                                    opacity: 0,
                                    transition: {
                                        duration: 0
                                    }
                                } : {
                                    opacity: 1,
                                    transition: {
                                        duration: 0.4
                                    }
                                }}
                            > */}
                                {renderActiveItem()}
                            {/* </motion.div> */}
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1`
    ${type.heading}
    ${tw`text-black`};
`
const Subheading = styled.h2``
const Description = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const ImageWrapper = styled.div``

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.4s ease;
`



// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}
`

// Introduction

const Intro = styled.div`
    width: 58rem;
    max-width: 100%;
    padding-top: 9rem;
    padding-bottom: 6rem;

    &, * {
        ${tw`font-display`};
        font-weight: 300;
        font-size: 2.25rem;
        line-height: 1.4;
    }

    > *:not(:first-child) {
        margin-top: 1em;
    }
`

// Grid

const Left = styled.div``
const Right = styled.div``

const Items = styled.div``
const Item = styled.div`
    ${tw`flex`};
    ${props => {
        if (props.active)
            return css`
                color: #c36646!important;
            `
    }}
`

// Grid

const Grid = styled.div`
    ${tw`flex justify-between`};
    margin-top: 2rem;
    margin-bottom: 12rem;

    ${media.mob`
        ${tw`block`};
    `}

    ${Left} {
        ${tw`w-1/3`};
    }

    ${Right} {
        ${tw`w-2/3`};
    }

    ${Left}, ${Right} {
        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        ${Items} {
            ${tw`flex flex-col items-start`};
        }

        ${Item} {
            &, * {
                ${tw`font-display`};
                font-weight: 300;
                font-size: 2.25rem;
                line-height: 1.4;
            }
        }
    }

    ${Right} {
        ${media.mob`
            margin-top: 3.5rem;
        `}
    }
`

// Services

const Services = styled.div`
    ${Subheading} {
        ${type.heading}
    }

    ${Items} {
        ${tw`flex flex-col`};
        margin-top: 2.5rem;
        
        ${Item} {
            ${type.body}
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "services" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default ServicesListing
