import React, { useState, useContext, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'
import { motion } from 'framer-motion'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'


const ActiveItem = ({activeItem, item}) => {
    const [loaded, setLoaded] = useState(false)

    const fadeVariants = {
        hidden: {
            opacity: 0,
            display: 'none',
            transition: {
                duration: 0
            }
        },
        visible: {
            opacity: 1,
            display: 'block',
            transition: {
                duration: 0.4
            }
        }
    }

    return (
        <motion.div
            initial={ 'hidden' }
            animate={ (activeItem.name === item.name) ? 'visible' : 'hidden' }
            variants={fadeVariants}
        >
            <ImageWrapper>
                {item.image && (
                    <Image
                        key={item?.image?.sizes?.medium2}
                        src={item?.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{opacity: loading ? 0 : 1}}
                                />
                            )
                        }}
                    </Image>
                )}
            </ImageWrapper>
            {item.bio && (
                <Description
                    dangerouslySetInnerHTML={{
                        __html: item.bio,
                    }}
                />
            )}
        </motion.div>
    )
}

const Team = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    const [activeItem, setActiveItem] = useState(data.our_team[0])
    // console.log(data)

    useMount(() => {
        if (data.our_team && data.our_team.length) {
            setActiveItem(data.our_team[0])
        }
        theme.setTheme({
            headerColour: colours.blue,
            bgColour: colours.lightblue,
            footerColour: colours.grey
        })
    })

    const renderTeam = () => {
        let items
        if (data.our_team) {
            items = data.our_team.map((item, i) => {
                return (
                    <Item
                        key={i}
                        href="javascript:void(0)"
                        onClick={() => setActiveItem(item)}
                        active={activeItem.name === item.name}
                    >
                        {item.name}
                    </Item>
                )
            })
        }

        return (
            <Items>
                {items}
            </Items>
        )
    }

    const renderTeamMembers = () => {
        if (!data.our_team) return

        const items = data.our_team.map((item, i) => {
            return (
                <ActiveItem
                    key={i}
                    item={item}
                    activeItem={activeItem}
                />
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    const renderAcknowledgement = () => {
        if (!data.acknowledgement_of_country) return

        return (
            <Acknowledgement
                dangerouslySetInnerHTML={{
                    __html: data.acknowledgement_of_country,
                }}
            />
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.grey}
        >
            <Wrapper>
                <Container>
                    <Grid>
                        <Left>
                            <Heading>Our Team</Heading>
                            {renderTeam()}
                        </Left>
                        <Right>
                            {renderTeamMembers()}
                        </Right>
                    </Grid>
                    {renderAcknowledgement()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const ImageWrapper = styled.div``

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.4s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``

const Items = styled.div``
const Item = styled.a`
    ${tw`flex`};
    ${props => {
        if (props.active)
            return css`
                color: ${colours.blue}!important;
            `
    }}
`


const Grid = styled.div`
    ${tw`flex justify-between`};
    margin-top: 5rem;

    ${media.mob`
        ${tw`block`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        margin-top: 4.5rem;

        ${Heading} {
            ${type.heading}
            ${tw`text-black2`};
        }

        ${Items} {
            ${tw`flex flex-col items-start`};
            margin-top: 2rem;
        }

        ${Item} {
            &, * {
                ${tw`font-display`};
                font-weight: 300;
                font-size: 2.25rem;
                line-height: 1.3;
                color: #99b1ce;
            }
        }
    }

    ${Right} {
        ${media.mob`
            margin-top: 3.5rem;
        `}

        ${ImageWrapper} {
            ${tw`relative w-full`};
            height: 20rem;
            margin-bottom: 3.5rem;

            ${LoadedImage} {
                ${tw`absolute inset-0 w-full h-full`};
                object-fit: contain;
                object-position: left;
            }
        }

        ${Description} {
            ${type.body}
            ${tw`text-black2`};
            min-height: 20rem;
            padding-bottom: 4rem;

            ${media.mob`
                min-height: initial;
                padding-bottom: 12rem;
            `}

            > *:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
`



const Acknowledgement = styled.div`
    padding-bottom: 4rem;

    &, * {
        ${tw`font-display text-blue`};
        font-weight: 300;
        font-size: 1rem;
    }
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "team" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Team
